import { REASSIGN_AMAZD, REASSIGN_AMAZD_FAIL, REASSIGN_AMAZD_SUCCESS } from '../../actions';
import { AmazdsState } from '../../types';
import { Action } from '../../types/action.types';

export const initialState = {
  errorMessage: '',
  currentAmazd: {
    amazd: null,
    loading: false,
  },
} as AmazdsState;

export const emptyList = {
  page: 0,
  expertOption: 'all-chats',
  loading: false,
  errorMessage: '',
};

export const amazdReducer = (state: AmazdsState = initialState, action: Action): AmazdsState | undefined => {
  switch (action.type) {
    case REASSIGN_AMAZD:
      return {
        ...state,
        errorMessage: '',
        currentAmazd: {
          amazd: null,
          loading: true,
        },
      };
    case REASSIGN_AMAZD_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
        currentAmazd: {
          loading: false,
          amazd: null,
        },
      };
    case REASSIGN_AMAZD_SUCCESS:
      return {
        ...state,
        currentAmazd: {
          amazd: null,
          loading: false,
        },
      };
  }
};
