import React from 'react';

import { UserAvatar } from '@amazd/common/components';
import { useImpersonation } from '@amazd/common/hooks/useImpersonation';
import { useMyShopDetails } from '@amazd/common/hooks/useShopDetails';
import { AmazdIcon } from '@amazd/common/icons';
import AlertIcon from '@amazd/common/icons/AlertIcon';
import ArrowDownIcon from '@amazd/common/icons/ArrowDownIcon';
import ArrowLeftIcon from '@amazd/common/icons/ArrowLeftIcon';
import BriefcaseIcon from '@amazd/common/icons/BriefcaseIcon';
import CallsMenuIcon from '@amazd/common/icons/CallsMenuIcon';
import ChatIcon from '@amazd/common/icons/ChatIcon';
import MenuIcon from '@amazd/common/icons/MenuIcon';
import MenuTeamIcon2 from '@amazd/common/icons/MenuTeamIcon2';
import SuccessIcon from '@amazd/common/icons/SuccessIcon';
import { UserRoleEnum } from '@amazd/common/types';
import { useTranslation } from '@amazd/common/utils/i18n';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';
import {
  AppBar,
  Badge,
  Button,
  ButtonBase,
  ButtonGroup,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import NotificationsMenu from 'components/NotificationsMenu';
import ProfilePopover from 'components/ProfilePopover';
import usePermission from 'hooks/usePermission';
import { useRouter } from 'next/router';
import { ProfileSubPages } from 'types/routing.types';

import HeaderBadge from './badge';
import { Link } from './link';
import { useStyles } from './styles';
import { HeaderProps } from './types';

export default function Header({
  isAuth,
  isWidgetScreen,
  user,
  userType,
  newNotificationCount,
  logoUrl,
  showDrawerButton,
  onDrawerButtonClick,
}: HeaderProps): JSX.Element {
  const { isImpersonating, handleResetImpersonation } = useImpersonation();
  const { shopDetails } = useMyShopDetails();

  const classes = useStyles({ isImpersonating });
  const router = useRouter();
  const { t } = useTranslation();

  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState<null | HTMLElement>(null);
  const canAccessCompanySettings = usePermission(Permission.COMPANY_SETTINGS);
  const canAccessTeam = usePermission(Permission.SEE_TEAM);

  const canSeeSuccess = () => {
    if (user?.role === UserRoleEnum.ADMIN || user?.role === UserRoleEnum.ROOT) return true;
    if (!shopDetails?.shopDomain) return false;

    if (hasAccessTo(user?.role, Permission.COMPANY_ORDERS)) return true;
    if (hasAccessTo(user?.role, Permission.MY_ORDERS)) {
      if (user?.role === UserRoleEnum.USER && !user?.userTypes?.find((type) => type.isExpert)) return false;
      return true;
    }
    return false;
  };

  const tabs = [
    ...(hasAccessTo(user?.role, Permission.CHAT)
      ? [
          {
            name: t('main-menu-chats'),
            path: '/',
            icon: <ChatIcon />,
          },
        ]
      : []),
    ...(hasAccessTo(user?.role, Permission.CALLS)
      ? [
          {
            name: t('main-menu-calls'),
            path: '/calls',
            icon: <CallsMenuIcon />,
          },
        ]
      : []),
    ...(hasAccessTo(user?.role, Permission.MANAGE_ALL_USERS)
      ? [
          {
            name: t('main-menu-users'),
            path: '/admin/users',
            icon: <MenuTeamIcon2 />,
          },
        ]
      : []),
    ...(hasAccessTo(user?.role, Permission.MANAGE_ALL_COMPANIES)
      ? [
          {
            name: t('main-menu-companies'),
            path: '/admin/companies',
            icon: <BriefcaseIcon />,
          },
        ]
      : []),
    ...(user?.role === UserRoleEnum.ROOT
      ? [
          {
            name: 'AMAZDS',
            path: '/admin/amazds',
            icon: <AmazdIcon />,
          },
        ]
      : []),
    ...(canSeeSuccess()
      ? [
          {
            name: t('main-menu-success'),
            path: '/success',
            icon: <SuccessIcon style={{ width: '24px', height: '24px' }} />,
          },
        ]
      : []),
  ];

  const handleProfileOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleAlertOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };
  const handleAlertClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleLogout = () => {
    router.push('/logout');
  };

  const handleProfileInfoClick = async () => {
    await router.push(`/profile/${ProfileSubPages.myProfile}`);
    handleProfileClose();
  };

  const handleLegalInfoClick = async () => {
    await router.push(`/profile/${ProfileSubPages.legalNotes}`);
    handleProfileClose();
  };

  const handleMyAvailabilitiesClick = async () => {
    await router.push(`/profile/${ProfileSubPages.myAvailabilities}`);
    handleProfileClose();
  };

  const handleBack = async () => {
    router.back();
  };

  return (
    <AppBar className={classes.root} position="relative">
      <Toolbar className={classes.toolbar}>
        {isWidgetScreen && (
          <IconButton onClick={handleBack} edge="start" color="primary" style={{ position: 'absolute', left: 20 }}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        {(!isWidgetScreen || logoUrl) && (
          <div className={classes.logoSection}>
            {showDrawerButton && (
              <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerButtonClick} edge="start">
                <MenuIcon />
              </IconButton>
            )}
            <Link href="/">
              <img src={logoUrl || '/amazd-logo-negative.png'} style={{ top: 'initial' }} className={classes.logo} />
            </Link>
            <HeaderBadge user={user} />
          </div>
        )}
        {!isAuth && (
          <div className={classes.container}>
            <ButtonGroup className={classes.tabs} variant="text" color="inherit" aria-label="text primary button group">
              {tabs.map(({ name, path, icon }) => {
                return (
                  <Link href={path} key={`tab-${name}`}>
                    <Button
                      className={`${classes.tab} ${path === router.pathname && classes.selectedTab}`}
                      startIcon={icon}
                    >
                      <span className={classes.tabLabel}>{name}</span>
                    </Button>
                  </Link>
                );
              })}
            </ButtonGroup>
            {canAccessTeam && (
              <Link href={'/team'}>
                <div className={router.pathname === '/team' ? classes.selectedIconTab : undefined}>
                  <IconButton className={classes.settings}>
                    <MenuTeamIcon2 />
                  </IconButton>
                </div>
              </Link>
            )}
            {canAccessCompanySettings && (
              <Link href={'/company-settings/company-profile'}>
                <div
                  className={
                    ['/company-settings/company-profile', '/company-settings/widget-configurator'].includes(
                      router.pathname,
                    )
                      ? classes.selectedIconTab
                      : undefined
                  }
                >
                  <IconButton className={classes.settings}>
                    <BriefcaseIcon />
                  </IconButton>
                </div>
              </Link>
            )}

            <IconButton
              aria-controls="notifications-menu"
              aria-haspopup="true"
              onClick={handleAlertOpen}
              className={classes.notification}
            >
              <Badge
                className={classes.notificationBadge}
                badgeContent={newNotificationCount}
                color="secondary"
                overlap="rectangular"
              >
                <AlertIcon />
              </Badge>
            </IconButton>
            <Popover
              onClick={() => setNotificationsAnchorEl(null)}
              PaperProps={{
                style: {
                  borderRadius: 20,
                  boxShadow: '0px 0px 20px rgba(114, 120, 136, 0.3)',
                },
              }}
              open={Boolean(notificationsAnchorEl)}
              onClose={handleAlertClose}
              anchorEl={notificationsAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {userType && <NotificationsMenu />}
            </Popover>

            <ButtonBase
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleProfileOpen}
              className={classes.profileContainer}
              data-testid="profile-menu"
            >
              <div className={classes.profileArrowDownButton}>
                <ArrowDownIcon />
              </div>
              <Typography className={classes.profileName}>{`${user?.firstName} ${user?.lastName}`}</Typography>
              <UserAvatar
                size={40}
                userType={userType}
                user={user}
                className={classes.avatar}
                data-testid="top-bar-user-avatar"
              />
            </ButtonBase>
            <Popover
              PaperProps={{
                style: {
                  borderRadius: 20,
                  boxShadow: '0px 0px 20px rgba(114, 120, 136, 0.3)',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id="profile-menu"
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileClose}
            >
              <ProfilePopover
                isExpert={userType?.isExpert}
                isInternal={user?.internal}
                onMyAvailabilitiesClick={handleMyAvailabilitiesClick}
                onProfileInfoClick={handleProfileInfoClick}
                onLegalInfoClick={handleLegalInfoClick}
                onLogout={handleLogout}
                onResetImpersonation={handleResetImpersonation}
              />
            </Popover>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
