import React from 'react';

import { useImpersonation } from '@amazd/common/hooks/useImpersonation';
import ArrowRightIcon from '@amazd/common/icons/ArrowRightIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import { useStyles } from './styles';
import { ProfilePopoverProps } from './types';

export default function ProfilePopover({
  isExpert,
  onLogout,
  onProfileInfoClick,
  onLegalInfoClick,
  onMyAvailabilitiesClick,
  onResetImpersonation,
}: ProfilePopoverProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isImpersonating } = useImpersonation();

  return (
    <div className={classes.root}>
      <Typography align="center" style={{ padding: 15 }}>
        {t('profile-my-header-my-profile')}
      </Typography>
      <Divider />
      <List>
        <ListItem button onClick={onProfileInfoClick}>
          <ListItemText primary={t('profile-my-menu-name-image-bio')} data-testid="my-profile-button" />
          <ListItemSecondaryAction className={classes.itemIcon}>
            <ArrowRightIcon />
          </ListItemSecondaryAction>
        </ListItem>
        {isExpert && (
          <>
            <Divider className={classes.divider} />
            <ListItem button onClick={onMyAvailabilitiesClick}>
              <ListItemText primary={t('profile-my-menu-my-availabilities')} data-testid="my-availabilities-button" />
              <ListItemSecondaryAction className={classes.itemIcon}>
                <ArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
          </>
        )}
        <Divider className={classes.divider} />
        <ListItem button onClick={onLegalInfoClick}>
          <ListItemText primary={t('profile-my-menu-legal-notes')} data-testid="legal-notes-button" />
          <ListItemSecondaryAction className={classes.itemIcon}>
            <ArrowRightIcon />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className={classes.divider} />
        {isImpersonating && (
          <>
            <ListItem button onClick={onResetImpersonation}>
              <ListItemText primary={'Stop impersonating user'} data-testid="stop-impersonation-button" />
              <ListItemSecondaryAction className={classes.itemIcon}>
                <ArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider className={classes.divider} />
          </>
        )}
        <ListItem button onClick={onLogout}>
          <ListItemText style={{ color: red[500] }} primary={t('profile-my-menu-logout')} data-testid="logout-button" />
        </ListItem>
      </List>
    </div>
  );
}
