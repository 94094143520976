import { useEffect, useState } from 'react';

import { LocalKeys, NotificationMessages } from '@amazd/common/redux/types';
import { useTranslation } from '@amazd/common/utils/i18n';

export const useNotificationTitle = (
  notificationMessages: NotificationMessages[],
): {
  title: string;
  content: string;
} => {
  const [info, setInfo] = useState({ title: '', content: '' });
  const { t, i18n } = useTranslation();
  useEffect(() => {
    generateTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, notificationMessages]);
  const generateTitle = () => {
    const key = i18n.language as keyof typeof LocalKeys;
    const locale = LocalKeys[key] || LocalKeys.en;
    const messagesMap = new Map();
    notificationMessages?.map((element) => {
      messagesMap.set(element.locale, { ...element });
    });
    if (messagesMap.get(locale)) {
      setInfo({
        title: messagesMap.get(locale).title,
        content: messagesMap.get(locale).content,
      });
    }
  };
  return info;
};
