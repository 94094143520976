import React from 'react';

import { UserAvatar, Loader } from '@amazd/common/components';
import { Notification, NotificationStatus } from '@amazd/common/redux/types';
import { colors } from '@amazd/common/static';
import { distanceToNow, truncateText } from '@amazd/common/utils';
import { useTranslation } from '@amazd/common/utils/i18n';
import { ButtonBase, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useNotificationTitle } from 'hooks/useNotificationTitle';

import useStyles from './styles';
import { NotificationsProps } from './types';

const NotificationItem = ({
  notification,
  isFirstItem,
  onClick,
}: {
  notification: Notification;
  isFirstItem: boolean;
  onClick: (n: Notification) => void;
}) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const notificationInfo = useNotificationTitle(notification.messages);

  const borderLeft = notification.status !== NotificationStatus.READ ? `5px solid ${colors.amazdPurple}` : 'none';
  return (
    <>
      <div
        style={{
          width: 'calc(100% - 30px)',
          margin: '0 auto',
          borderTop: isFirstItem ? 'none' : '0.5px solid #e6e6e6',
        }}
      ></div>
      <ButtonBase
        onClick={() => {
          onClick(notification);
        }}
      >
        <ListItem style={{ borderLeft }} className={classes.listItem} alignItems="flex-start">
          <ListItemAvatar>
            <UserAvatar size={40} userType={notification.creatorAsUserType} />
          </ListItemAvatar>
          <ListItemText
            primary={notificationInfo.title}
            secondary={
              <React.Fragment>
                {notificationInfo.content && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {`${truncateText(notificationInfo.content)} - `}
                  </Typography>
                )}
                <Typography component="span" variant="caption" style={{ color: colors.amazdGrey4 }}>
                  {distanceToNow(i18n.language, new Date(notification.createdAt))}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </ButtonBase>
    </>
  );
};

const SCROLL_THRESHOLD_PX = 50;

const Notifications: React.FC<NotificationsProps> = ({
  notificationList,
  onNotificationClick,
  onLoadNextNotifications,
}: NotificationsProps) => {
  const classes = useStyles();

  const handleScroll = (e: any) => {
    const reached = e.target.scrollHeight - e.target.scrollTop - SCROLL_THRESHOLD_PX <= e.target.clientHeight;
    if (reached) {
      onLoadNextNotifications();
    }
  };

  return (
    <Grid className={classes.itemsContainer} onScroll={handleScroll}>
      <List className={classes.list}>
        {notificationList?.data.map((notification: Notification, index: number) => {
          return (
            <NotificationItem
              onClick={onNotificationClick}
              isFirstItem={index === 0}
              key={notification.id}
              notification={notification}
            />
          );
        })}
      </List>
      {notificationList.loading && <Loader size={75} />}
    </Grid>
  );
};

export default Notifications;
