import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    width: 375,
  },
  title: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #D6DAE4',
  },
  tab: {
    padding: 15,
  },
}));
