import React from 'react';

import { Button, ButtonTypeMap, ExtendButtonBase } from '@mui/material';

import useStyles from './styles';

const LinkButton: ExtendButtonBase<ButtonTypeMap> = ({ children, className, ...props }: any) => {
  const classes = useStyles();

  return (
    <Button {...props} className={`${className} ${classes.root}`} disableRipple>
      {children}
    </Button>
  );
};

export default LinkButton;
