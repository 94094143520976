import React from 'react';

import { Typography, TypographyProps as MuiTypographyProps } from '@mui/material';

export interface TypographyProps extends MuiTypographyProps {
  children: string;
  component?: string;
  width?: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TypographyTruncated: React.FC<TypographyProps> = ({ style, width, children, ...rest }: TypographyProps) => {
  return (
    <Typography
      title={children}
      style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width, ...style }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographyTruncated;
