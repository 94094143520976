/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect';

import { initialState } from '../../reducers/amazdsReducer';
import { AmazdDetailsState } from '../../types';

const selectState = (state: any): AmazdDetailsState => state.amazdDetails || initialState;

export const makeSelectAvailableSlotsByDate = () =>
  createSelector(selectState, (state: AmazdDetailsState) => state.availableSlotsByDate);
export const makeSelectAvailableDurations = () =>
  createSelector(selectState, (state: AmazdDetailsState) => state.availableDurations);
export const makeSelectAvailableSlotsLoading = () =>
  createSelector(selectState, (state: AmazdDetailsState) => state.availableSlotsLoading);
export const makeSelectBookLoading = () =>
  createSelector(selectState, (state: AmazdDetailsState) => state.bookSlotLoading);
