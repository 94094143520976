import { makeStyles } from '@mui/styles';
const APP_BAR_HEIGHT = 54;

export default makeStyles(() => ({
  root: {
    height: '100%',
  },
  appBar: {
    height: APP_BAR_HEIGHT,
  },
  mainContainer: {
    // position: 'relative',
    // width: '100%',
    height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
    overflow: 'auto',
  },
}));
