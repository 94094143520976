import { makeStyles } from '@mui/styles';

import { colors } from '../../static';

export default makeStyles(() => ({
  root: {
    padding: 0,
    '&.MuiButton-root:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
    color: colors.amazdGrey3,
    fontWeight: 400,
  },
}));
