import { useAuthInfo } from '@amazd/common/hooks/auth';
import { hasAccessTo, Permission } from '@amazd/common/utils/permissions';

const usePermission = (permission: Permission) => {
  const { ownUser } = useAuthInfo();

  if (!ownUser) {
    return false;
  }
  return hasAccessTo(ownUser.role, permission);
};

export default usePermission;
