import { colors } from '@amazd/common/static';
import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';
import { toolbarHeight } from 'styles/theme';

interface StyleProps {
  isImpersonating?: boolean;
}

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: (props: StyleProps) => (props.isImpersonating ? 'darkred' : theme.palette.grey[900]),
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 27,
    width: 85,
    marginRight: 18,
    cursor: 'pointer',
  },
  tabs: {
    flex: 1,
    justifyContent: 'center',
    minHeight: 54,
    '& button:first-child': {
      borderLeft: '1px solid #727888',
    },
  },
  tab: {
    width: 148,
    borderRadius: 'unset',
    paddingRight: 20,
    paddingLeft: 20,
    color: colors.amazdGrey4,
    stroke: colors.amazdGrey4,
    borderRight: '1px solid #727888 !important',
    lineHeight: '20px',
    fontSize: 14,
  },
  tabLabel: {
    lineHeight: '20px',
  },
  selectedTab: {
    color: 'white',
    stroke: 'white',
    backgroundColor: 'rgba(114, 120, 136, 0.4)',
  },
  selectedIconTab: {
    color: 'white',
    backgroundColor: 'rgba(114, 120, 136, 0.4)',
  },
  settings: {
    borderLeft: '1px solid #727888',
    borderRight: '1px solid #727888',
    borderRadius: 'unset',
    color: 'inherit',
    height: 54,
    width: 60,
  },
  notification: {
    borderLeft: '1px solid #727888',
    borderRight: '1px solid #727888',
    borderRadius: 'unset',
    color: 'inherit',
    height: 54,
    width: 60,
  },
  container: {
    minHeight: 54,
    width: '100%',
    display: 'flex',
  },
  profileContainer: {
    display: 'flex',
  },
  profileName: {
    display: 'flex',
    alignItems: 'center',
  },
  profileArrowDownButton: {
    marginLeft: 16,
    marginRight: 16,
  },
  avatar: {
    marginLeft: 16,
  },
  toolbar: {
    minHeight: toolbarHeight,
    paddingLeft: 20,
    paddingRight: 24,
  },
  managerBadge: {
    height: 20,
    color: 'black',
    fontSize: '12px',
    backgroundColor: colors.amazdAccent1,
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  ownerBadge: {
    height: 20,
    color: 'black',
    fontSize: '12px',
    backgroundColor: '#D1A5FF', // this color is not in the theme, as it is only used here
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  notificationBadge: {
    '& .MuiBadge-anchorOriginTopRightRectangle-badge': {
      top: '10px',
      right: '10px',
      padding: '0px',
      fontSize: '10px',
    },
    '& .MuiBadge-badge': {
      padding: '0 3px',
      height: '13px',
      top: '4px',
      right: 'unset',
      left: '5px',
      fontSize: '10px',
      minWidth: '13px',
    },
  },
}));
