import { useEffect } from 'react';

import { notificationsActions } from '@amazd/common/redux/actions';
import { notificationsSelectors } from '@amazd/common/redux/selectors';
import { NotificationList, NotificationStatus } from '@amazd/common/redux/types';
import { useDispatch, useSelector } from 'react-redux';

const useNotificationLoader = (): [NotificationList, () => void, () => void] => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelectors.makeSelectAllNotifications());

  useEffect(() => {
    if (!notifications.loading && notifications.data.length === 0) {
      dispatch(notificationsActions.getNotifications(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNext = () => {
    if (!notifications.loading && notifications.page !== -1) {
      dispatch(notificationsActions.getNotifications(notifications.page + 1));
    }
  };

  const readNotifications = () => {
    const ids = notifications.data
      .filter((notification) => {
        return notification.status !== NotificationStatus.READ;
      })
      .map((notification) => notification.id);
    if (ids.length) {
      dispatch(notificationsActions.updateNotifications(ids));
    }
  };

  return [notifications, loadNext, readNotifications];
};

export default useNotificationLoader;
