import React, { useEffect } from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { Typography } from '@mui/material';
import Notifications from 'components/Notifications';
import useNotificationLoader from 'hooks/useNotificationLoader';
import { useOpenNotification } from 'hooks/useOpenNotifications';

import useStyles from './styles';

const NotificationsMenu: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [notifications, loadNextNotifications, readNotifications] = useNotificationLoader();
  const openNotification = useOpenNotification();

  useEffect(() => {
    if (notifications?.data?.length) {
      readNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.data?.length]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="subtitle1">
        {t('notifications-title')}
      </Typography>
      <Notifications
        notificationList={notifications}
        onNotificationClick={openNotification}
        onLoadNextNotifications={loadNextNotifications}
      />
    </div>
  );
};

export default NotificationsMenu;
