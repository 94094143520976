import { colors } from '@amazd/common/static';
import { grey, green } from '@mui/material/colors';
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';

// temporary until we completely move everything to common
export { colors } from '@amazd/common/static';

export const layoutHighlightingBorderColor = '1px solid #e0e0e0';
export const toolbarHeight = 54;
export const chatSideBarWidth = 375;
export const boxShadow = '0px 0px 20px 0px rgb(114, 120, 136, 0.3)';
export const listItemBorder = '0.5px solid #C9C9C9';
export const gradientBackground =
  'linear-gradient(24deg, rgba(255,238,199,1) 0%, rgba(255,252,242,1) 20%, rgba(255,255,255,1) 39%)';
export const screenSize1280 = 1280;
export const screenSize1920 = 1920;

export const buttonProgress: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: 'FilsonPro',
    h6: {
      fontSize: 14,
      fontWeight: 500,
    },
    h5: {
      fontSize: 22,
      fontWeight: 400,
    },
    h4: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: colors.amazdGrey0,
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 10,
      lineHeight: '14px',
      fontWeight: 400,
    },
  },
  palette: {
    grey: {
      900: 'black',
      500: colors.amazdGrey,
      300: colors.amazdGrey3,
    },
    primary: {
      main: colors.amazdPurple,
      light: colors.amazdPurple,
      dark: colors.amazdPurple,
    },
    /**
     * Default secondary color from MUI4. Not sure was this color intended or used accidentally
     */
    secondary: {
      main: '#f50057',
      light: '#f50057',
      dark: '#f50057',
    },
    success: {
      main: green[500],
    },
  },
  components: {
    // Style sheet name
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiCard: {
      // Name of the rule
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow,
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiCardContent: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[100]}`,
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[100]}`,
          padding: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 20,
          boxShadow,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 17px) scale(1)',
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: '12px',
            marginRight: '12px',
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-root': {
            lineHeight: 1,
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '14px 12px !important',
        },
        root: {
          borderRadius: '8px !important',
          height: '48px',
          '&.MuiAutocomplete-inputRoot': {
            height: 'auto',
          },
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.grey40,
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.amazdPurple,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336',
          },
        },
      },
    },
    // Deleted theme override for MuiSelect. Use components/Select
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 120,
          padding: 15,
          textTransform: 'initial',
        },
        containedPrimary: {
          boxShadow: 'none !important',
        },
        sizeSmall: {
          fontSize: 12,
          padding: '3px 9px',
        },
        containedSizeSmall: {
          padding: 4,
          paddingLeft: 10,
          paddingRight: 10,
        },
        outlined: {
          padding: 12,
          paddingLeft: 24,
          paddingRight: 24,
          fontWeight: 400,
        },
        contained: {
          paddingLeft: 80,
          paddingRight: 80,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 40,
          width: 40,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
          fontSize: 14,
          padding: '11px 13px',
          borderRadius: 8,
        },
        arrow: {
          color: 'black',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: '16px',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 15,
          fontSize: 22,
          fontWeight: 500,
          lineHeight: '36px',
          '& .MuiTypography-root': {
            fontSize: 'inherit !important',
            lineHeight: 'inherit !important',
            fontWeight: 'inherit !important',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.amazdGrey5,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
        },
      },
    },
  },
  spacing: 1,
});

export interface CustomTheme extends Theme {
  readonly isFullscreen: boolean;
  readonly widgetBackgroundCover: Record<string, string | number>;
  readonly widgetChatTabsHeight: number;
  readonly widgetChatExpertHeaderHeight: number;
}
