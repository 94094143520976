import React from 'react';

import { useImpersonation } from '@amazd/common/hooks/useImpersonation';
import { User, UserRoleEnum } from '@amazd/common/types';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Chip } from '@mui/material';

import { useStyles } from './styles';

interface HeaderBadgeProps {
  user?: User;
}

const ManagerBadge = () => {
  const { isImpersonating } = useImpersonation();
  const classes = useStyles({ isImpersonating });
  const { t } = useTranslation();
  return <Chip className={classes.managerBadge} label={t('topbar-manager-badge')} />;
};

const OwnerBadge = () => {
  const { isImpersonating } = useImpersonation();
  const classes = useStyles({ isImpersonating });
  const { t } = useTranslation();
  return <Chip className={classes.ownerBadge} label={t('topbar-owner-badge')} />;
};

const HeaderBadge = ({ user }: HeaderBadgeProps) => {
  switch (user?.role) {
    case UserRoleEnum.MANAGER:
      return <ManagerBadge />;
    case UserRoleEnum.OWNER:
      return <OwnerBadge />;
    default:
      return null;
  }
};

export default HeaderBadge;
