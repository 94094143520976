import { MyShopDetails, ShopPlatform } from '@amazd/common/types';
import { gql, useQuery } from '@apollo/client';

const shopDetailsQuery = gql`
  query MyShopDetails {
    shopDetailsByMyCompany {
      shopDomain
      shopPlatform
      defaultCurrency
      features {
        checkout
      }
    }
  }
`;

export const getShopPlatformLabel = (shopPlatform?: ShopPlatform) => {
  switch (shopPlatform) {
    case ShopPlatform.MAGENTO:
      return 'Magento';
    case ShopPlatform.SHOPIFY:
      return 'Shopify';
    case ShopPlatform.SHOPWARE_V6:
      return 'Shopware 6';
    default:
      return '';
  }
};

export function useMyShopDetails() {
  const { loading, data, error } = useQuery<{ shopDetailsByMyCompany: MyShopDetails }>(shopDetailsQuery, {
    fetchPolicy: 'cache-first',
  });

  return {
    shopDetails:
      data?.shopDetailsByMyCompany &&
      ({
        ...data?.shopDetailsByMyCompany,
        shopPlatformLabel: getShopPlatformLabel(data?.shopDetailsByMyCompany?.shopPlatform),
      } as MyShopDetails),
    loading,
    error,
  };
}

export const shopDetailsByAmazdIdQuery = gql`
  query shopDetailsByAmazdId($amazdId: String!) {
    shopDetailsByAmazdId(amazdId: $amazdId) {
      shopDomain
      shopPlatform
      features {
        checkout
      }
    }
  }
`;

export function useShopDetailsByAmazdId(amazdId?: string) {
  const { loading, data, error } = useQuery<{
    shopDetailsByAmazdId: {
      shopDomain?: string;
      shopPlatform?: ShopPlatform;
      features?: {
        checkout?: boolean;
      };
    };
  }>(shopDetailsByAmazdIdQuery, {
    variables: {
      amazdId,
    },
    fetchPolicy: 'cache-first',
    skip: !amazdId,
  });

  return {
    details: {
      ...data?.shopDetailsByAmazdId,
      shopPlatformLabel: getShopPlatformLabel(data?.shopDetailsByAmazdId?.shopPlatform),
    },
    loading,
    error,
  };
}
