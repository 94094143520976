import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: CustomTheme) => ({
  itemsContainer: {
    height: 'calc(100vh - 152px)',
    overflowY: 'scroll',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listItem: {
    paddingRight: 30,
    paddingLeft: 30,
  },
}));
