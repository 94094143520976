/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect';

import { initialState } from '../../reducers/notificationsReducer';
import { NotificationsState } from '../../types/notification.types';

const selectState = (state: any): NotificationsState => state.notifications || initialState;

export const makeSelectAllNotifications = () =>
  createSelector(selectState, (state: NotificationsState) => state.allNotifications);

export const makeSelectNewNotificationCount = () =>
  createSelector(selectState, (state: NotificationsState) => state.newNotificationsCount);
