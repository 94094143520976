import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: 375,
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    marginLeft: 13,
    marginRight: 13,
  },
  quickActionsList: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
