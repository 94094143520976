import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SuccessIcon: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    style={{ fill: 'transparent', ...props.style }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.2914 11.8417V8.5835H14.0332" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20.3878 5.48713L21.4198 4.45508"
      stroke="#FFB13F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1634 4.45508H5.9391C4.79868 4.45508 3.875 5.37876 3.875 6.51917V18.9037C3.875 20.0442 4.79868 20.9678 5.9391 20.9678H19.3557C20.4961 20.9678 21.4198 20.0442 21.4198 18.9037V13.7435"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.4198 8.58341H22.875" stroke="#FF0066" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.2916 4.45519V3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.875 13.3515C12.1314 15.2061 16.0016 9.87354 16.0016 9.87354"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SuccessIcon;
