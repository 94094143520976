import { Notification, NotificationEvent } from '@amazd/common/redux/types';
import { useRouter } from 'next/router';

export const useOpenNotification = (): ((notification: Notification) => Promise<any>) => {
  const router = useRouter();

  const openNotification = async (notification: Notification) => {
    const data: { amazdId: string } = JSON.parse(notification.data);

    switch (notification.event) {
      case NotificationEvent.UNKNOWN:
      case NotificationEvent.APPOINTMENT_CANCELED:
      case NotificationEvent.REMIND_BEFORE_APPOINTMENT:
      case NotificationEvent.NEW_MESSAGE:
      case NotificationEvent.NEW_PROPOSAL:
      case NotificationEvent.AMAZD_REASSIGNMENT:
        if (data.amazdId) {
          return await router.push({ pathname: '/', query: { amazdId: data.amazdId } });
        }
        return;
      case NotificationEvent.INCOMING_VIDEO_CALL:
      case NotificationEvent.JOIN_VIDEO_CALL_NOW:
      case NotificationEvent.APPOINTMENT_SCHEDULED:
        if (data.amazdId) {
          return await router.push({ pathname: '/', query: { amazdId: data.amazdId, showCallsModal: true } });
        }
        return;
      case NotificationEvent.SURVEY_ANSWERED:
        if (data.amazdId) {
          return await router.push({ pathname: '/', query: { amazdId: data.amazdId, openSurveysTab: true } });
        }
        return;
      default:
        return;
    }
  };
  return openNotification;
};
